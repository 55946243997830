<template>
  <layout-base>
    <v-layout class="overflow-inherit!">
      <v-main>
        <slot />
      </v-main>
    </v-layout>

    <!-- <app-footer /> -->
    <footer>
      <v-container>
        <v-divider class="mb-2!" />

        <div class="sublinks">
          <v-row align-content="center" class="ma-0 px-0">
            <v-col cols="12" md="auto" class="pa-0">
              <v-row class="h-full" align-content="center" no-gutters>
                <span>
                  © {{ new Date().getFullYear() }} Orie. All rights reserved.
                </span>
              </v-row>
            </v-col>

            <v-col cols="12" md="auto" class="pa-0 ml-md-10">
              <v-row class="h-full" align-content="center" no-gutters>
                <template v-for="(link, i) in legalLinks" :key="link.text">
                  <span class="mx-2" v-if="i">·</span>
                  <router-link class="dotted-link" :to="link.to">
                    {{ link.text }}
                  </router-link>
                </template>
              </v-row>
            </v-col>

            <v-spacer class="md:hidden" />

            <v-col cols="12" md="auto" class="md:hidden">
              <span class="mr-5">
                Handcrafted with
                <span class="text-red">
                  <v-icon size="small" icon="favorite" />
                </span>
              </span>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </footer>
  </layout-base>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LayoutBase from "@/components/layout/modules/LayoutBase.vue";

export default defineComponent({
  components: {
    LayoutBase,
  },
  data: () => ({
    legalLinks: [
      {
        text: "Terms of Use",
        to: "/legal/terms",
      },
      {
        text: "Privacy Policy",
        to: "/legal/privacy",
      },
      {
        text: "Sales",
        to: "/legal/sales",
      },
      {
        text: "Legal",
        to: "/legal",
      },
    ],
  }),
});
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 4rem;
  padding-bottom: 1rem;
  background-color: rgba(230, 195, 68, 0.223);
  display: block;
  width: 100vw;
  mark {
    display: inline-block;
    line-height: 0em;
    padding-bottom: 0.5em;
  }
  .sublinks {
    width: 100%;
    font-size: 0.7rem;
    @include breakpoints-up(md) {
      font-size: 0.8rem;
    }
  }
  @include breakpoints-down(md) {
    margin-bottom: 50px;
  }
}
</style>
